////////////////////////////////////////////////////////////////////////////////
// Initial Javascript file
////////////////////////////////////////////////////////////////////////////////

// ES5 forEach polyfill for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Initiate base classes
new Menu();
new Tabs();
new Carousel();

// Add animate on scroll
AOS.init({
  duration: 600,
  offset:200,
  easing:'ease-in-out-cubic'
});
