class Carousel {

  constructor(){

    this.interval = false;
    this.intervalTiming = 4000;
    this.carousel = document.querySelector('process-carousel');

    if(this.carousel){
      this.itemCount = this.carousel.children.length;
      this.bindHandlers();
      this.start();
    }

  };

  runAnimation(reverse = false){

    let firstIndex = this.carousel.firstElementChild.getAttribute('data-index');

    let baseIndex = reverse ? parseInt(firstIndex) : parseInt(firstIndex) - 2;

    let items = this.carousel.querySelectorAll('a.carousel-item');

    items.forEach((item, index) => {

      baseIndex++;
      if(baseIndex > this.itemCount){ baseIndex = 1; }
      if(!reverse){
        if(baseIndex < 1){ baseIndex = this.itemCount; }
      }

      item.setAttribute('data-index', baseIndex);

    });

  };

  bindHandlers(){

    this.navLeft = document.querySelector('#carousel-left');

    this.navLeft.addEventListener('click', () => {
      clearInterval(this.interval);
      this.runAnimation(true);
      this.start();
    });

    this.navRight = document.querySelector('#carousel-right');

    this.navRight.addEventListener('click', () => {
      clearInterval(this.interval);
      this.runAnimation();
      this.start();
    });

  }

  start(){
    this.interval = setInterval(() => this.runAnimation(), this.intervalTiming);
  }

}
