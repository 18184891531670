class Menu {

  constructor() {

    this.trigger = document.querySelector('.trigger');

    if(this.trigger){
      this.bindHandlers();
    }

  }

  bindHandlers(){

    this.trigger.addEventListener('click', () => {
      document.querySelector('body').classList.toggle('menu');
    });

  }

}
