class Tabs {

  constructor(){

    this.selectedClass = "selected";

    this.tabs = document.querySelectorAll('#tabbed-content .tabs > a');
    this.headers = document.querySelectorAll('#tabbed-content .content > a');
    this.contents = document.querySelectorAll('#tabbed-content .tab-content');

    if(this.tabs){
      this.bindHandlers();
    }

  }

  bindHandlers(){

    this.tabs.forEach((tab, index) => {

      tab.addEventListener('click', () => {
        this.selectTab(index);
      })

    })

    this.headers.forEach((header, index) => {

      header.addEventListener('click', () => {
        this.selectTab(index);
        this.headers[index].scrollIntoView({ behavior: 'smooth' });
      })

    })

    let linkToTab1 = document.querySelectorAll('[data-tab="1"]');
    let linkToTab2 = document.querySelectorAll('[data-tab="2"]');
    let linkToTab3 = document.querySelectorAll('[data-tab="3"]');
    let linkToTab4 = document.querySelectorAll('[data-tab="4"]');

    if(linkToTab1){
      linkToTab1.forEach((link, index) => {
        link.addEventListener('click', event => {
          event.preventDefault();
          this.selectTab(0);
        })
      })
    }

    if(linkToTab2){
      linkToTab2.forEach((link, index) => {
        link.addEventListener('click', event => {
          event.preventDefault();
          this.selectTab(1);
        })
      })
    }

    if(linkToTab3){
      linkToTab3.forEach((link, index) => {
        link.addEventListener('click', event => {
          event.preventDefault();
          this.selectTab(2);
        })
      })
    }

    if(linkToTab4){
      linkToTab4.forEach((link, index) => {
        link.addEventListener('click', event => {
          event.preventDefault();
          this.selectTab(3);
        })
      })
    }

  }

  selectTab(i){

    this.tabs.forEach(tab => {
      tab.classList.remove(this.selectedClass)
    });

    this.tabs[i].classList.add(this.selectedClass)

    this.contents.forEach(content => {
      content.classList.remove(this.selectedClass)
    });

    this.contents[i].classList.add(this.selectedClass)

  }

}
